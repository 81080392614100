import React from 'react';

const AboutUs = () => {
    return (
        <div>
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{ maxWidth: "900px" }}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">About Us</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item active text-primary">About</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid about overflow-hidden py-5 px-5">
                <h1 className="text-primary text-center mb-5">SEIKETSU Water Tank Cleaning Services</h1>

                <section className="mb-5">
                    <h2 className="text-primary">Introduction</h2>
                    <p>
                        At <strong>SEIKETSU Solutions Private Limited</strong>, we are driven by a mission to revolutionize the way water tank cleaning and disinfection are approached in India. Clean water is a basic necessity for every household, institution, and commercial establishment, yet the potential for contamination is often overlooked. Our primary objective is to protect public health by ensuring that water storage tanks are thoroughly cleaned and disinfected to prevent the spread of waterborne diseases. We understand that clean water is vital not just for drinking but for various other purposes, including food preparation, sanitation, and industrial use.
                    </p>
                    <p>
                        With over six years of industry experience, SEIKETSU has grown to become a trusted name in tank cleaning services. Our team of dedicated professionals, equipped with the latest technology and industry-leading methods, ensures that every tank we clean meets the highest hygiene standards.
                    </p>
                </section>

                <section className="mb-5">
                    <h2 className="text-primary">Why Tank Cleaning is Crucial</h2>
                    <p>
                        The cleanliness of water tanks directly impacts the quality of water being consumed or used. Over time, sediments, bacteria, algae, and other contaminants build up in storage tanks, making the water unsafe for use. These contaminants can cause severe waterborne diseases such as <strong>Typhoid</strong>, <strong>Cholera</strong>, <strong>Hepatitis A</strong>, and <strong>Food Poisoning</strong>, among others.
                    </p>
                    <p>
                        It’s easy to overlook the need for regular tank cleaning, especially when the water appears clear at first glance. However, water contamination can occur without visible signs, making it even more dangerous. Our services at SEIKETSU are aimed at preventing this contamination and keeping your water supply safe. We don’t just clean tanks – we provide peace of mind by ensuring your family or employees have access to clean and safe water.
                    </p>
                </section>

                <section className="mb-5">
                    <h2 className="text-primary">Our Commitment to Hygiene and Safety</h2>
                    <p>
                        We understand the importance of delivering services that prioritize both health and environmental safety. SEIKETSU is committed to using eco-friendly, non-toxic cleaning agents that effectively disinfect without harming the environment. Our cleaning solutions are certified safe for water tanks and will not affect the water quality or taste once the tank is refilled.
                    </p>
                    <p>
                        Our cleaning staff undergoes rigorous training to stay up-to-date with the latest industry practices and technologies. They follow strict hygiene protocols, wearing personal protective equipment (PPE) during all cleaning processes to ensure their safety and yours. Additionally, we take great care in cleaning up after every service, ensuring that no mess is left behind for you to handle.
                    </p>
                </section>

                <section className="mb-5">
                    <h2 className="text-primary">Who Can Benefit from SEIKETSU’s Services?</h2>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Residential Properties – </strong>We provide household water tank cleaning services to ensure that your family is protected from the dangers of contaminated water.</li>
                        <li className="list-group-item"><strong>Commercial Buildings – </strong>Businesses and office buildings often have large water storage tanks that supply water for various purposes. We ensure that your water remains clean and safe for use by employees and visitors alike.</li>
                        <li className="list-group-item"><strong>Hospitals and Clinics </strong>– Medical facilities rely heavily on clean water for sanitation, cleaning, and treatment. Our specialized cleaning services ensure that hospitals maintain the highest levels of hygiene.</li>
                        <li className="list-group-item"><strong>Schools and Universities </strong>– Educational institutions must provide students and staff with safe water. Regular tank cleaning is crucial to maintaining a healthy learning environment.</li>
                        <li className="list-group-item"><strong>Hotels and Restaurants </strong>– Food and beverage industries need clean water for cooking, cleaning, and general hygiene. We help these businesses maintain their reputation for quality and safety.</li>
                        <li className="list-group-item"><strong>Industrial Facilities – </strong>Large-scale industries with massive water storage systems require professional cleaning services to keep their water tanks free from harmful contaminants.</li>
                    </ul>
                </section>

              

                <section className="text-center">
                    <h2 className="text-primary mb-4">Get in Touch with Us Today!</h2>
                    <p>Ready to ensure your water is safe and clean? Contact us to book a service or inquire about our offerings!</p>
                    <a href="/contact-us" className="btn btn-primary btn-lg">Contact Us</a>
                </section>
            </div>
        </div>
    );
};

export default AboutUs;