import React from "react";

const Home = () => {
    return (
        <>
            <div className="container-fluid position-relative p-0">
                <div className="carousel-header">
                    <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                            <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                            <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active">
                                <img src="assets/img/carousel-1.jpg" className="img-fluid w-100" alt="Image" />
                                <div className="carousel-caption-1">
                                    <div className="carousel-caption-1-content" style={{ maxWidth: "900px" }}>
                                        <h4 className="text-white text-uppercase fw-bold mb-4 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1s" style={{ animationDelay: "1s", letterSpacing: "3px" }}>Importance life</h4>
                                        <h1 className="display-2 text-capitalize text-white mb-4 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.3s" style={{ animationDelay: "1.3s" }}>Crystal Clear Solutions </h1>
                                        <p className="mb-5 fs-5 text-white fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.5s" style={{ animationDelay: "1.5s" }}>Clean Today for a Better Tomorrow
                                        </p>
                                        <div className="carousel-caption-1-content-btn fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.7s" style={{ animationDelay: "1.7s" }}>
                                            <a className="btn btn-primary rounded-pill flex-shrink-0 py-3 px-5 ms-2" href="/contact-us">Free Estimate</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="assets/img/carousel-2.jpg" className="img-fluid w-100" alt="Image" />
                                <div className="carousel-caption-2">
                                    <div className="carousel-caption-2-content" style={{ maxWidth: "900px" }}>
                                        <h4 className="text-white text-uppercase fw-bold mb-4 fadeInRight animated" data-animation="fadeInRight" data-delay="1s" style={{ animationDelay: "1s", letterSpacing: "3px" }} >Importance life</h4>
                                        <h1 className="display-2 text-capitalize text-white mb-4 fadeInRight animated" data-animation="fadeInRight" data-delay="1.3s" style={{ animationDelay: "1.3s" }}>Safe Water, Stronger Communities</h1>
                                        <p className="mb-5 fs-5 text-white fadeInRight animated" data-animation="fadeInRight" data-delay="1.5s" style={{ animationDelay: "1.5s" }}>Ensuring Purity, One Drop at a Time
                                        </p>
                                        <div className="carousel-caption-2-content-btn fadeInRight animated" data-animation="fadeInRight" data-delay="1.7s" style={{ animationDelay: "1.7s" }}>
                                            <a className="btn btn-primary rounded-pill flex-shrink-0 py-3 px-5 ms-2" href="/contact-us">Free Estimate</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon btn btn-primary fadeInLeft animated" aria-hidden="true" data-animation="fadeInLeft" data-delay="1.1s" style={{ animationDelay: "1.3s" }}> <i className="fa fa-angle-left fa-3x"></i></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                            <span className="carousel-control-next-icon btn btn-primary fadeInRight animated" aria-hidden="true" data-animation="fadeInLeft" data-delay="1.1s" style={{ animationDelay: "1.3s" }}><i className="fa fa-angle-right fa-3x"></i></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid about overflow-hidden py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="about-img rounded" style={{ height: "85%" }}>
                                <img src="assets/img/tank.png" className="img-fluid rounded  w-100" style={{ objectFit: "cover", height: "100%" }} alt="" />
                                <div className="about-exp"><span>20 Years Experience</span></div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="about-item">
                                <h4 className="text-primary text-uppercase">About Us</h4>
                                <h1 className="display-3 mb-3">We Deliver The Quality Water.</h1>
                                <p className="mb-4">Our reliable water tank cleaning services in India remove impurities like sand, mud, algae, viruses, and bacteria using advanced equipment such as high-pressure jet cleaners, UV radiation, and bio-sanitizers.
                                </p>
                                <div className="bg-light rounded p-4 mb-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="pe-4">
                                                    <div className="rounded-circle bg-primary d-flex align-items-center justify-content-center" style={{ width: "80px", height: "80px" }}><i className="fas fa-tint text-white fa-2x"></i></div>
                                                </div>
                                                <div className="">
                                                    <a href="#" className="h4 d-inline-block mb-3">Satisfied Customer</a>
                                                    <p className="mb-0">A satisfied customer is our greatest achievement, reflecting trust and quality in every service we provide.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light rounded p-4 mb-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="pe-4">
                                                    <div className="rounded-circle bg-primary d-flex align-items-center justify-content-center" style={{ width: "80px", height: "80px" }}><i className="fas fa-faucet text-white fa-2x"></i></div>
                                                </div>
                                                <div className="">
                                                    <a href="#" className="h4 d-inline-block mb-3">Standard Product</a>
                                                    <p className="mb-0">Our standard products deliver consistent quality, reliability, and performance you can count on.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="/about-us" className="btn btn-secondary rounded-pill py-3 px-5">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid feature bg-light py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
                        <h4 className="text-uppercase text-primary">How Seiketsu Works</h4>
                        <h1 className="display-3 text-capitalize mb-3">A Trusted Cleaning Industry</h1>
                    </div>
                    <div className="row g-4 align-items-stretch">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-cube text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Cleaning of Surrounding</p>
                                <p className="mb-3">Ensuring a cleaner and healthier environment through effective surrounding cleaning solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-hand-holding-water text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Mechanized dewatering</p>
                                <p className="mb-3">Efficient Water Removal, Maximized Productivity – Powering Solutions with Mechanized Dewatering.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-life-ring text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Sludge removal</p>
                                <p className="mb-3">Effective Sludge Removal – Efficiently Clearing the Path for Cleaner<br /> Water Solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-cog text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Scrubbing</p>
                                <p className="mb-3">Deep scrubbing for spotless and thoroughly cleaned <br />surfaces.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-recycle text-white fa-3x"></i></div>
                                <p className="h4 mb-3">High pressure cleaning</p>
                                <p className="mb-3">Unleashing the Power of High Pressure – For a Deep, Lasting <br />Clean</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-adjust text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Vacuum cleaning</p>
                                <p className="mb-3">Precision Vacuum Cleaning – Removing Dust and Debris with<br /> Ease</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-filter text-white fa-3x"></i></div>
                                <p className="h4 mb-3">Anti bacteria spray</p>
                                <p className="mb-3">Defend Against Germs – Powerful Protection with Every<br /> Spray.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-3">
                                <div className="feature-icon mb-3"><i className="fas fa-microscope text-white fa-3x"></i></div>
                                <p className="h4 mb-3">UV radiation</p>
                                <p className="mb-3">Harnessing UV Radiation – Safe, Clean, and Effective<br /> Disinfection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid service overflow-hidden py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInDown" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
                        <h4 className="text-uppercase text-primary">Why SEIKETSU</h4>
                        <h6 className="display-5 text-capitalize mb-3">Innovating the future of water tank cleaning & disinfection</h6>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-3 col-lg-3 wow fadeInDown" data-wow-delay="0.5s" >
                            <div className="card border-0 shadow h-100 position-relative overflow-hidden rounded-lg rotate-on-hover">
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <div className="icon-container bg-primary mb-4 rounded-circle">
                                        <i className="fas fa-rocket text-white fa-2x"></i>
                                    </div>
                                    <h5 className="card-title">India's Fastest Emerging Brand</h5>
                                    <p className="card-text">
                                        We are leading the charge in mechanized cleaning & disinfection of water storage tanks, ensuring health and safety with cutting-edge solutions.
                                    </p>
                                    <div className="progress progress-bar-striped mb-2" style={{ height: '6px' }}>
                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: '90%' }}></div>
                                    </div>
                                    <p className="card-text">90% Customer Satisfaction</p>
                                </div>
                                <div className="custom-ribbon">New</div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 wow fadeInDown" data-wow-delay="0.7s" >
                            <div className="card border-0 shadow h-100 position-relative overflow-hidden rounded-lg rotate-on-hover">
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <div className="icon-container bg-primary mb-4 rounded-circle">
                                        <i className="fas fa-newspaper text-white fa-2x"></i>
                                    </div>
                                    <h5 className="card-title">Featured in Leading Media</h5>
                                    <p className="card-text">
                                        Our innovative business model has been highlighted in The Times of India, Economic Times, and many more leading publications.
                                    </p>
                                    <div className="progress progress-bar-striped mb-2" style={{ height: '6px' }}>
                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: '90%' }}></div>
                                    </div>
                                    <p className="card-text">90% Media Coverage</p>
                                </div>
                                <div className="custom-ribbon">Top Rated</div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 wow fadeInDown" data-wow-delay="0.9s" >
                            <div className="card border-0 shadow h-100 position-relative overflow-hidden rounded-lg rotate-on-hover">
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <div className="icon-container bg-primary mb-4 rounded-circle">
                                        <i className="fas fa-water text-white fa-2x"></i>
                                    </div>
                                    <h5 className="card-title">Expertise in Tank Cleaning</h5>
                                    <p className="card-text">
                                        We clean tanks from 500 liters to 37 lakh liters with industry-leading technology and precision, ensuring superior service.
                                    </p>
                                    <div className="progress progress-bar-striped mb-2" style={{ height: '6px' }}>
                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: '95%' }}></div>
                                    </div>
                                    <p className="card-text">95% Expertise Rating</p>
                                </div>
                                <div className="custom-ribbon">Pro</div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 wow fadeInDown" data-wow-delay="1s">
                            <div className="card border-0 shadow h-100 position-relative overflow-hidden rounded-lg rotate-on-hover">
                                <div className="card-body d-flex flex-column justify-content-between ">
                                    <div className="icon-container bg-primary mb-4 rounded-circle">
                                        <i className="fas fa-handshake text-white fa-2x"></i>
                                    </div>
                                    <h5 className="card-title ">Trusted by Corporates</h5>
                                    <p className="card-text ">
                                        We have earned the trust of large corporate clients with consistent service and quality, delivering excellence for over 6 years.
                                    </p>
                                    <div className="progress progress-bar-striped mb-2" style={{ height: '6px' }}>
                                        <div className="progress-bar" role="progressbar" style={{ width: '92%' }}></div>
                                    </div>
                                    <p className="card-text">92% Corporate Approval</p>
                                </div>
                                <div className="custom-ribbon">Trusted</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid service bg-light  overflow-hidden py-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
                        <h4 className="text-uppercase text-primary">Our Service</h4>
                        <h1 className="display-3 text-capitalize mb-3">Ensure Safe Water with Professional Cleaning</h1>
                    </div>
                    <div className="row gx-0 gy-4 align-items-center">
                        <div className="col-lg-6 col-xl-4 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="service-item rounded p-4 mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="service-content text-end">
                                                <a href="#" className="h4 d-inline-block mb-3">Residential Waters</a>
                                                <p className="mb-0">We offer household water tank cleaning to protect your family from contaminated water.</p>
                                            </div>
                                            <div className="ps-4">
                                                <div className="service-btn"><i className="fas fa-hand-holding-water text-white fa-2x"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item rounded p-4 mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="service-content text-end">
                                                <a href="#" className="h4 d-inline-block mb-3">Commercial Waters</a>
                                                <p className="mb-0">We provide clean water tank services for businesses, protecting employees and customers from contamination..</p>
                                            </div>
                                            <div className="ps-4">
                                                <div className="service-btn"><i className="fas fa-dumpster-fire text-white fa-2x"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item rounded p-4 mb-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="service-content text-end">
                                                <a href="#" className="h4 d-inline-block mb-3">Filtration Plants</a>
                                                <p className="mb-0">Our filtration plant cleaning services ensure optimal performance, providing clean, safe water for all applications.</p>
                                            </div>
                                            <div className="ps-4">
                                                <div className="service-btn"><i className="fas fa-filter text-white fa-2x"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bg-transparent" style={{ margin: 10 }}>
                                <img src="assets/img/water.png" className="img-fluid w-100" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-4 wow fadeInRight" data-wow-delay="0.2s">
                            <div className="service-item rounded p-4 mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="pe-4">
                                                <div className="service-btn"><i className="fas fa-assistive-listening-systems text-white fa-2x"></i></div>
                                            </div>
                                            <div className="service-content">
                                                <a href="#" className="h4 d-inline-block mb-3">Water Softening</a>
                                                <p className="mb-0">Our water softening services prevent scale buildup, ensuring your plumbing and appliances last longer.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item rounded p-4 mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="pe-4">
                                                <div className="service-btn"><i className="fas fa-recycle text-white fa-2x"></i></div>
                                            </div>
                                            <div className="service-content">
                                                <a href="#" className="h4 d-inline-block mb-3">Market Research</a>
                                                <p className="mb-0">We conduct thorough market research to understand customer needs and tailor our services effectively.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item rounded p-4 mb-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex">
                                            <div className="pe-4">
                                                <div className="service-btn"><i className="fas fa-project-diagram text-white fa-2x"></i></div>
                                            </div>
                                            <div className="service-content">
                                                <a href="#" className="h4 d-inline-block mb-3">Project Planning</a>
                                                <p className="mb-0">We specialize in project planning to ensure timely, efficient execution of water treatment solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;