import React from "react";

const Services = () => {
    return (
        <>
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{ maxWidth: "900px" }}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Tank Cleanning</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item active text-primary">Tank Cleanning</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid about overflow-hidden py-5 px-5">
                <h1 className="text-primary text-center mb-5"> Tank cleanning solutions</h1>
                <section className="mb-5">
                    <p>At SEIKETSU, we offer a wide array of water tank cleaning services tailored to the specific needs of our clients. Whether you have a small household tank or a large industrial water storage unit, our team is equipped to handle it all. We clean tanks of various sizes and types, including overhead tanks, underground tanks, plastic tanks, concrete tanks, and more.</p>

                    <p>Our cleaning process involves several key steps:</p>
                    <div className="list-group">
                        <div className="list-group-item">
                            <h5>1. Cleaning of Surrounding</h5>
                            <p>We first clean the surrounding area of the tank to ensure a hygienic environment, preventing any potential contaminants from entering the water supply.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>2. Mechanized Dewatering</h5>
                            <p>We remove the stagnant water from the tank using mechanized equipment to ensure that no water remains inside.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>3. Sludge Removal</h5>
                            <p>Once the water is drained, the accumulated sludge at the bottom of the tank is thoroughly removed to prevent any residual contamination.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>4. Scrubbing</h5>
                            <p>We scrub the walls, floor, and ceiling of the tank manually or with mechanical tools to loosen any stubborn dirt, algae, or deposits before the high-pressure cleaning.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>5. High-Pressure Cleaning</h5>
                            <p>Using high-pressure jet cleaning, we scrub the walls, floor, and ceiling of the tank to remove algae, dirt, and any other deposits.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>6. Vacuum Cleaning</h5>
                            <p>The loosened dirt and sludge are then vacuumed out, ensuring no debris is left behind.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>7. Anti-Bacterial Spray</h5>
                            <p>We use a specialized anti-bacterial spray that kills harmful microorganisms, providing a second layer of protection.</p>
                        </div>
                        <div className="list-group-item">
                            <h5>8. UV Disinfection</h5>
                            <p>As the final step, we utilize UV radiation to completely disinfect the tank, ensuring that it is safe and free from any bacteria or viruses.</p>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <h2 className="text-primary">Waterborne Diseases – A Major Threat</h2>
                    <p>Contaminated water is a breeding ground for a variety of diseases that can severely impact human health. These include:</p>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Typhoid Fever</strong> – A bacterial infection that leads to severe stomach pain, vomiting, diarrhea, and dehydration.</li>
                        <li className="list-group-item"><strong>Cholera</strong> – An acute diarrheal infection caused by ingesting water contaminated with Vibrio cholerae bacteria.</li>
                        <li className="list-group-item"><strong>Hepatitis A</strong> – A viral liver disease that spreads through the consumption of contaminated water or food.</li>
                        <li className="list-group-item"><strong>Food Poisoning</strong> – Caused by consuming water contaminated with harmful bacteria such as Salmonella or E. coli.</li>
                        <li className="list-group-item"><strong>Bacterial Dysentery</strong> – An inflammatory disease of the intestines, caused by bacteria such as Shigella, resulting in severe diarrhea.</li>
                    </ul>
                </section>

                <section className="mb-5">
                    <h2 className="text-primary">Why Choose SEIKETSU?</h2>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Expertise and Experience</strong> – With over six years of experience in the industry, we are the leaders in water tank cleaning and disinfection services across India.</li>
                        <li className="list-group-item"><strong>Cutting-Edge Technology</strong> – We use the latest mechanized cleaning equipment to ensure thorough cleaning of your water tanks.</li>
                        <li className="list-group-item"><strong>Customer Satisfaction</strong> – At SEIKETSU, customer satisfaction is our priority.</li>
                        <li className="list-group-item"><strong>Tailored Services</strong> – We offer flexible service packages to suit your specific requirements.</li>
                        <li className="list-group-item"><strong>Health and Safety</strong> – Our cleaning processes are designed to eliminate harmful bacteria, viruses, and contaminants from your water tanks.</li>
                        <li className="list-group-item"><strong>Competitive Pricing</strong> – We offer our premium cleaning services at highly competitive prices.</li>
                    </ul>
                </section>
            </div>
        </>
    )
}

export default Services;