import React from "react";

const Footer = () => {
    return (
        <>
            <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
                <div className="container py-5">

                    <div className="row g-5">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <div className="footer-item">
                                    <a href="/" >
                                        <img src="assets/img/logo.png" alt="Logo" style={{ width: "200px", marginTop: "-20px", marginBottom: "20px" }} />
                                    </a>
                                    <p className="mb-3">Process Addresses The Need Of Cleaning Of Tanks Most Scientifically, Professionally, Efficiently And Fast….</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="text-white mb-4">About Us</h4>
                                <a href="/"><i className="fas fa-angle-right me-2"></i> Home</a>
                                <a href="/about-us"><i className="fas fa-angle-right me-2"></i> About</a>
                                <a href="/services"><i className="fas fa-angle-right me-2"></i> Service</a>
                                <a href="/contact-us"><i className="fas fa-angle-right me-2"></i> Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="text-white mb-4">Business Hours</h4>
                                <div className="mb-3">
                                    <h6 className="text-muted mb-0">Mon - Friday:</h6>
                                    <p className="text-white mb-0">09.00 am to 07.00 pm</p>
                                </div>
                                <div className="mb-3">
                                    <h6 className="text-muted mb-0">Saturday:</h6>
                                    <p className="text-white mb-0">10.00 am to 05.00 pm</p>
                                </div>
                                <div className="mb-3">
                                    <h6 className="text-muted mb-0">Vacation:</h6>
                                    <p className="text-white mb-0">All Sunday is our vacation</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="text-white mb-4">Contact Info</h4>
                                <a className="d-flex align-items-baseline" href="#"><i className="fa fa-map-marker-alt me-2 " style={{ textAlign: "right" }}></i> <p className="m-2">B-131, Pramukh arcade,<br /> reliance cross road kudasan, gandhinagar,Gujarat, 382421 ,India</p></a>
                                <a href="mailto:info@seiketsu.in"><i className="fas fa-envelope me-2"></i> info@seiketsu.in</a>
                                <a href="tel:07940029911"><i className="fas fa-phone me-2"></i> +91 78742 70924</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright py-4">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6 text-center text-md-start mb-md-0">
                            <span className="text-body"><a href="#" className="border-bottom text-white"><i className="fas fa-copyright text-light me-2"></i>Seiketsu</a>, All right reserved.</span>
                        </div>
                    </div>
                </div>
            </div>

            <a className="btn btn-secondary btn-lg-square rounded-circle back-to-top" aria-label="Chat on WhatsApp" href="https://wa.me/7874270924?text=Hello%2C%20I%20would%20like%20to%20inquire%20about%20your%20water%20tank%20cleaning%20services.%20Could%20you%20please%20provide%20information%20on%20the%20cleaning%20process%2C%20availability%2C%20and%20pricing%3F%20Thank%20you." target="_blank">
                <img src="assets/img/whatsapp.webp" height={110} /> </a>

        </>
    )
}
export default Footer;