import React, { useEffect, useState } from "react";

const Header = () => {
    const [loading, setLoading] = useState(true);
    const urlSearchString = window.location.href.split("/").pop();


    const arr = [
        {
            no: 1,
            name: "Home",
            redirect: "home"
        },
        {
            no: 2,
            name: "About",
            redirect: "about-us"
        },
        {
            no: 3,
            name: "Service",
            redirect: "services"
        },
        {
            no: 4,
            name: "Contact",
            redirect: "contact-us"
        }
    ]

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500)
    }, [])
    return (
        <>
            {loading ? <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> : ""}
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <a href="/" className="navbar-brand p-0">
                    <img src="assets/img/logo.png" alt="Logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        {arr.map(x => (
                            <a href={x.redirect} className={`nav-item nav-link ${urlSearchString === x.redirect && "active"}`}>{x.name}</a>
                        ))}

                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;