import React, { useRef, useState } from "react";

function App() {

    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch("https://script.google.com/macros/s/AKfycbzGHoik9Z5KzukgCSSgo7IuoMI9bRRsWeuugcagrroirNCI0ump7-ZCozPqI3B5bNeE/exec", {
            method: 'POST',
            body: new FormData(formRef.current),
        }).then(res => {
            setLoading(false);
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).then(data => {
            setLoading(false);
            alert("Your Inquiry has been sent successfully");
        }).catch(err => {
            setLoading(false);
            console.error('There was a problem with the fetch operation:', err);
            alert('Submission failed. Please try again.');
        });
    };



    return (
        <>
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{ maxWidth: "900px" }}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Contact Us</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item active text-primary">Contact</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid contact bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-6 h-100 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="text-center mx-auto pb-5" style={{ maxWidth: "800px" }}>
                                <h4 className="text-uppercase text-primary">Let’s Connect</h4>
                                <h1 className="display-3 text-capitalize mb-3">Send Your Message</h1>
                            </div>
                            <form onSubmit={handleSubmit} ref={formRef}>
                                <div className="row g-4">
                                    <div className="col-lg-12 col-xl-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control border-0" required name="name" id="name" placeholder="Your Name" />
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control border-0" required name="email" id="email" placeholder="Your Email" />
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-12">
                                        <div className="form-floating">
                                            <input type="phone" className="form-control border-0" required name="phone" id="phone" placeholder="Phone" />
                                            <label for="phone">Your Phone</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control border-0" required placeholder="Leave a message here" id="comments" name="comments" style={{ height: "175px" }}></textarea>
                                            <label for="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <button disabled={loading} type="submit" className="btn btn-primary w-100 py-3 d-flex justify-content-center align-items-center gap-3">
                                            Send Message
                                            {loading && (
                                                <div className="spinner-border text-white" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            )}
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="row g-4">
                                <div className="col-12">
                                    <div className="d-inline-flex rounded bg-white w-100 p-4">
                                        <i className="fas fa-map-marker-alt fa-2x text-secondary me-4"></i>
                                        <div>
                                            <h4>Address</h4>
                                            <p>B-131, Pramukh arcade, reliance cross road kudasan, gandhinagar,Gujarat, 382421 ,India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="d-inline-flex rounded bg-white w-100 p-4">
                                        <i className="fas fa-envelope fa-2x text-secondary me-4"></i>
                                        <div>
                                            <h4>Mail Us</h4>
                                            <p className="mb-0">info@seiketsu.in</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="d-inline-flex rounded bg-white w-100 p-4">
                                        <i className="fa fa-phone-alt fa-2x text-secondary me-4"></i>
                                        <div>
                                            <h4>Telephone</h4>
                                            <p className="mb-0">+91 78742 70924</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="h-100 overflow-hidden">
                                        <iframe className="w-100 rounded" style={{ height: "400px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.622280991756!2d72.62647107563463!3d23.18398207906106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2ba229ce9251%3A0xb4456ce6a3e1d7cd!2sPramukh%20Arcade%20complex!5e0!3m2!1sen!2sin!4v1728383796235!5m2!1sen!2sin"
                                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
