import React from "react";

const Services = () => {
    return (
        <>
            <div className="container-fluid bg-breadcrumb">
                <div className="container text-center py-5" style={{ maxWidth: "900px" }}>
                    <h4 className="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Services</h4>
                    <ol className="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item active text-primary">Services</li>
                    </ol>
                </div>
            </div>
            <div className="container-fluid service overflow-hidden py-5">
                <div className="container py-5">
                    <div className="row g-4">
                        <div className="col-md-3 col-lg-3 wow fadeInDown" data-wow-delay="0.5s" >
                            <a href="/tank-cleanning">
                                <div className="card border-0 shadow position-relative rounded-lg rotate-on-hover " style={{ backgroundImage: "url('assets/img/tankclean.png')", backgroundSize: 'cover', height: "300px" }} >
                                    <div className="card-body d-flex flex-column justify-content-center serviceHover" >
                                        <h3 className="card-title text-center text-white">Water Tank Cleanning Services</h3>
                                    </div>
                                    <div className="custom-ribbon">New</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services;